export default function cleanDateFilters(filterValue) {
  for (let i = 0; i < filterValue?.length; i++) {
    if (Array.isArray(filterValue[i])) {
      let currFilter = filterValue[i];
      if (currFilter?.[2] instanceof Date) {
        currFilter[2] = currFilter[2].toISOString().split("T")[0];
      } else {
        for (let j = 0; j < currFilter.length; j++) {
          if (
            Array.isArray(currFilter[j]) &&
            currFilter[j][2] instanceof Date
          ) {
            currFilter[j][2] = currFilter[j][2].toISOString().split("T")[0];
          }
        }
      }
    }
  }
  return filterValue;
}
